import { onMounted, reactive, ref } from 'vue'
import restMethods from '@/utility/restMethods'

const headerData = reactive({
  usedAmount: 0,
  remainAmount: 0,
  userMail: '',
})

const loading = ref(false)

export function useTopMenu() {

  const load = () => {

    loading.value = true

    restMethods.postForWebApp('/get-balance').then(response => {

      if ( ! response.hasError()) {

        const data = response.getData()

        headerData.usedAmount = data.usedAmount
        headerData.remainAmount = data.remainAmount
        headerData.userMail = data.userMail

        loading.value = false

      }

    })

  }

  onMounted(() => {

    load()

  })

  const reload = () => {

    load()

  }

  return {
    headerData,
    loading,
    reload,
  }

}