<template>
  <a-spin :spinning="state.isLoading">
    <sdPageHeader :title="$t.cargoRegisterForm" />
    <Main>
      <BasicFormWrapper class="mb-25">
        <sdCards>
          <a-form
            :model="state.selectedForm"
            name="multi-form"
            layout="horizontal"
            @submit.prevent="handleSubmit"
          >
            <a-row :gutter="30">
              <a-col
                :sm="12"
                :xs="24"
                class="mb-25"
              >
                <a-form-item label="Ad">
                  <a-input
                    v-model:value="state.selectedForm.name"
                    placeholder="Ad"
                    :tabindex="1"
                  />
                </a-form-item>
                <a-form-item label="İl">
                  <a-select
                    v-model:value="state.selectedForm.city"
                    size="large"
                    name="basic-select"
                    class="sDash_fullwidth-select"
                    placeholder="İl Seçiniz"
                    :tabindex="3"
                    @change="getTownViaCity"
                  >
                    <a-select-option
                      v-for="item in state.cities"
                      :key="item.code"
                      :value="item.name"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item
                  label="Telefon Numarası"
                  name="phone"
                >
                  <a-input
                    v-model:value="state.selectedForm.phone"
                    type="text"
                    class="form-control"
                    placeholder="Telefon Numarası"
                    :tabindex="5"
                    @keydown="isPhoneNumber($event)"
                  />
                </a-form-item>
                <a-form-item label="Sipariş Numarası">
                  <a-input
                    v-model:value="state.selectedForm.order_number"
                    placeholder="Sipariş Numarası"
                    :tabindex="7"
                  />
                </a-form-item>
              </a-col>
              <a-col
                :sm="12"
                :xs="24"
                class="mb-25"
              >
                <a-form-item label="Soyad">
                  <a-input
                    v-model:value="state.selectedForm.surname"
                    placeholder="Soyad"
                    :tabindex="2"
                  />
                </a-form-item>
                <a-form-item label="İlçe">
                  <a-select
                    v-model:value="state.selectedForm.town"
                    size="large"
                    name="basic-select"
                    class="sDash_fullwidth-select"
                    :tabindex="4"
                    placeholder="İlçe Seçiniz"
                  >
                    <a-select-option
                      v-for="town in state.townList"
                      :key="town.id"
                      :value="town.name"
                    >
                      {{ town.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="Adres">
                  <a-input
                    v-model:value="state.selectedForm.address"
                    placeholder="Adres"
                    :tabindex="6"
                  />
                </a-form-item>
                <a-form-item label="Toplam Desi">
                  <a-input
                    v-model:value="state.selectedForm.cbm"
                    placeholder="Toplam Desi"
                    :tabindex="8"
                    @keydown="isNumber($event)"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col
                :sm="12"
                :xs="24"
              >
                <div class="sDash_form-action">
                  <button
                    type="submit"
                    class="bn5"
                  >
                    Kargoyu Gönder
                  </button>
                </div>
              </a-col>
            </a-row>
          </a-form>
        </sdCards>
      </BasicFormWrapper>
    </Main>
  </a-spin>
</template>

<script>

import { notification } from 'ant-design-vue'
import { defineComponent, nextTick, onMounted, reactive } from 'vue'

import { BasicFormWrapper, Main } from '@/view/styled'

import { t } from '@/utility/language'
import restMethods from '@/utility/restMethods'
import { useTopMenu } from '@/composables/useTopMenu'

export default defineComponent({
  name: 'SendManualCargo',
  components: {
    Main,
    BasicFormWrapper,
  },
  setup() {

    const { reload } = useTopMenu()

    const state = reactive({
      cities: [],
      townList: [],
      selectedForm: {
        name: null,
        surname: null,
        city: null,
        town: null,
        phone: null,
        address: null,
        order_number: null,
        cbm: null,
      },
      isLoading: false,
    })

    const validationRules = [
      {
        field: 'name',
        minLength: 3,
        errorMessage: t.name_is_too_short,
      },
      {
        field: 'surname',
        minLength: 3,
        errorMessage: t.surname_is_too_short,
      },
      {
        field: 'phone',
        minLength: 15,
        errorMessage: t.phone_is_too_short,
      },
      {
        field: 'address',
        minLength: 5,
        errorMessage: t.address_is_too_short,
      },
      {
        field: 'city',
        minLength: 3,
        errorMessage: t.city_is_too_short,
      },
      {
        field: 'town',
        minLength: 3,
        errorMessage: t.town_is_too_short,
      },
      {
        field: 'order_number',
        minLength: 3,
        errorMessage: t.order_number_is_too_short,
      },
      {
        field: 'cbm',
        minLength: 1,
        errorMessage: t.cbm_is_too_short,
      },
    ]

    onMounted(() => {

      getCity()

    })

    const openNotificationWithIcon = (type, getMessage, getDescription) => {
      notification[type]({
        message: getMessage,
        description: getDescription,
      })
    }

    const formatPhoneNumber = (value) => {
      const onlyNums = value.replace(/[^\d]/g, '') // Sadece sayıları al
      if (onlyNums.length < 4) return onlyNums
      if (onlyNums.length < 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 8)} ${onlyNums.slice(8, 10)}`
    }

    const isPhoneNumber = (evt) => {
      evt = evt || window.event
      const charCode = evt.which || evt.keyCode
      const inputElement = evt.target

      // Input'ta zaten 15'den (parantezler ve boşluklar dahil) fazla karakter varsa ekstra girişi engelle
      if (inputElement.value.length >= 15 && (charCode !== 8 && charCode !== 46)) { // 8: Backspace, 46: Delete
        evt.preventDefault()
        return false
      }

      // Eğer silme veya delete tuşlarına basılmışsa formatlamayı atla
      if (charCode === 8 || charCode === 46) {
        return true
      }

      // Sadece sayıların girişine izin ver
      if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
        evt.preventDefault()
        return false
      } else {
        nextTick(() => {
          inputElement.value = formatPhoneNumber(inputElement.value)
        })
        return true
      }
    }

    const isNumber = (evt) => {
      evt = evt || window.event
      const charCode = evt.which || evt.keyCode
      const inputElement = evt.target

      if (inputElement.value.length >= 3 && (charCode !== 8 && charCode !== 46)) { // 8: Backspace, 46: Delete
        evt.preventDefault()
        return false
      }

      if (charCode === 8 || charCode === 46) {
        return true
      }

      if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
        evt.preventDefault()
        return false
      }
    }

    const getCity = () => {

      state.isLoading = true

      restMethods.postForTSoftKargoWebApp('/send-manual-shipment-open').then(response => {
        if ( ! response.hasError()) {

          const data = response.getData()

          state.selectedForm.order_number = data.next_order_no
          state.cities = data.cities

        }

        state.isLoading = false

      })
    }

    const getTownViaCity = (value) => {
      const keyOfCity = getKeyByValue(value)
      if (keyOfCity === null) {
        return false
      }
      state.isLoading = true
      restMethods.postForWebApp('/get-towns/' + keyOfCity).then(response => {
        if ( ! response.hasError()) {
          state.townList = response.getData().items
        }
        state.isLoading = false
      })
    }

    const getKeyByValue = (value) => {

      for (const item of state.cities) {
        if (item.name === value) {
          return item.code
        }
      }

      return null
    }

    const validateForm = () => {
      for (let rule of validationRules) {
        if (state.selectedForm[rule.field] === null || state.selectedForm[rule.field].length < rule.minLength) {
          openNotificationWithIcon(
            'error',
            t.error,
            rule.errorMessage,
          )
          return false
        }
      }
      return true
    }

    const handleSubmit = () => {
      if ( ! validateForm()) {
        state.isLoading = false
        return false
      }
      state.isLoading = true
      restMethods.postForTSoftKargoWebApp('/send-manual-shipment-submit', state.selectedForm, { show_success: true })
        .finally(() => {
          state.isLoading = false
          reload()
        })

    }

    return {
      state,

      isPhoneNumber,
      isNumber,
      getTownViaCity,
      handleSubmit,
    }

  },
})

</script>

<style>
.bn5 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.bn5:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowingbn5 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowingbn5 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.bn5:active {
  color: #000;
}

.bn5:active:after {
  background: transparent;
}

.bn5:hover:before {
  opacity: 1;
}

.bn5:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #191919;
  left: 0;
  top: 0;
  border-radius: 10px;
}
</style>
